exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-coupon-jsx": () => import("./../../../src/pages/coupon.jsx" /* webpackChunkName: "component---src-pages-coupon-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-loss-jsx": () => import("./../../../src/pages/loss.jsx" /* webpackChunkName: "component---src-pages-loss-jsx" */),
  "component---src-pages-memorial-jsx": () => import("./../../../src/pages/memorial.jsx" /* webpackChunkName: "component---src-pages-memorial-jsx" */),
  "component---src-pages-pray-jsx": () => import("./../../../src/pages/pray.jsx" /* webpackChunkName: "component---src-pages-pray-jsx" */)
}

